const config = {
  apiGateway: {
    REGION: 'us-east-1',
    INTERNAL_API_URL: 'https://fba8oc0b6h.execute-api.us-east-1.amazonaws.com/prod',
    TARGET_API_URL: 'https://nat6jbwi6k.execute-api.us-east-1.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.staging.riotinto.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: '',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.8.0',
    HOSTNAME: 'https://targets.staging.riotinto.forwoodsafety.com'
  }
};

export default config;